import groupAdminsMgt from '../../assets/images/admins.png';
import studentRecords from '../../assets/images/folders.svg';
import studentPhotos from '../../assets/images/photography.svg';
import institutionSetupImg from '../../assets/images/data.svg';
import registrationImg from '../../assets/images/registration.svg';
import feesImg from '../../assets/images/tax-calculate.svg';
import programmesImg from '../../assets/images/book.svg';
import admissionImg from '../../assets/images/knowledge.svg';
import identityImg from '../../assets/images/staff-student-identification.svg';
import UniversalPaymentsImg from '../../assets/images/wallet.svg';
import businessIntelligenceImg from '../../assets/images/business-intelligence.svg';
import resultMgtImg from '../../assets/images/exam.svg';
import courseMgtImg from '../../assets/images/execution.svg';
import studentTransactionImg from '../../assets/images/briefcase.svg';
import scheduleImg from '../../assets/images/schedule.svg';
import welfareImg from '../../assets/images/welfare.svg';
import humanResourceImg from '../../assets/images/human-resource-management.svg';
import institutionPolicyImg from '../../assets/images/policy.svg';
import academicDocImg from '../../assets/images/academic-documents.png';
import estatesMgtImg from '../../assets/images/estates-management.svg';
import eVotingImg from '../../assets/images/e-voting.svg';
import studentSupportImg from '../../assets/images/technical-support.png';
import medicalServicesImg from '../../assets/images/medical-services.svg';
import outreachMgtImg from '../../assets/images/outreach-management.svg';
import pointOfServiceImg from '../../assets/images/point-of-service.svg';
import studentClearances from '../../assets/images/student-clearance-mgt.svg';
import ntcResultsImg from '../../assets/images/ntc-results.png';
import lecturerMgtImg from '../../assets/images/lecturer-icon.svg';
import integrationImg from '../../assets/images/integration.svg';
import pujabImg from '../../assets/images/pujab.png';
import itsDataImg from '../../assets/images/time.png';
import auditTrailsImg from '../../assets/images/userLogs.png';

import routePath from './routePath';
import apps from './apps';

const appRoutes = [
  {
    ...apps.dashboard,
    img: institutionSetupImg,
    isProtected: false,
    order: 0,
    subAppRoutes: [
      {
        id: 1,
        ...routePath.dashboard,
        permissions: [],
      },
    ],
  },
  {
    ...apps.institutionSetUp,
    img: institutionSetupImg,
    isProtected: true,
    order: 1,
    subAppRoutes: [
      {
        id: 1,
        ...routePath.institutionDashboard,
        permissions: [],
        order: 2,
        hidden: false,
      },
      {
        id: 2,
        ...routePath.institutionStructure,
        permissions: ['CAN VIEW INSTITUTION STRUCTURE'],
        order: 1,
        hidden: false,
      },
      {
        id: 3,
        ...routePath.college,
        contains: 'Colleges',
        order: 3,
        permissions: ['CAN VIEW COLLEGES'],
        hidden: true,
      },
      {
        id: 4,
        ...routePath.faculties,
        contains:
          process.env.REACT_APP_INSTITUTION_FACULTY_ALIAS || 'Faculties',
        hidden: true,
        order: 4,
        permissions: ['CAN VIEW COLLEGES'],
      },
      {
        id: 5,
        ...routePath.departments,
        hidden: false,
        order: 5,
        permissions: ['CAN VIEW SCHOOLS/FACULTY'],
      },
      {
        id: 6,
        ...routePath.metadata,
        hidden: false,
        order: 6,
        permissions: ['CAN VIEW METADATA'],
      },
    ],
  },
  {
    ...apps.userMgt,
    img: groupAdminsMgt,
    isProtected: true,
    order: 2,
    subAppRoutes: [
      {
        id: 1,
        ...routePath.userAccessApp,
        order: 1,
        permissions: ['CAN VIEW SYSTEM APPS'],
      },
      {
        id: 2,
        ...routePath.userRole,
        order: 2,
        permissions: ['CAN VIEW ROLE GROUPS'],
      },
    ],
  },
  {
    ...apps.programmeMgt,
    img: programmesImg,
    isProtected: true,
    order: 3,
    subAppRoutes: [
      {
        id: 1,
        ...routePath.programmesDashboard,
        permissions: [],
        order: 2,
      },
      {
        id: 2,
        ...routePath.programme,
        permissions: ['CAN VIEW PROGRAMMES'],
        order: 1,
      },
      {
        id: 3,
        ...routePath.programmeSetting,
        permissions: [],
        order: 3,
      },
      {
        id: 4,
        ...routePath.courseUnits,
        permissions: [],
        order: 4,
      },
      {
        id: 5,
        ...routePath.programmeSearch,
        permissions: [],
        order: 5,
      },
    ],
  },
  {
    ...apps.feesMgt,
    img: feesImg,
    isProtected: true,
    order: 4,
    subAppRoutes: [
      {
        id: 1,
        ...routePath.feesManagement,
        permissions: ['CAN VIEW FEES ELEMENTS'],
      },
      {
        id: 2,
        ...routePath.tuition,
        permissions: ['CAN VIEW TUITION FEES AMOUNTS'],
      },
      {
        id: 3,
        ...routePath.functionalFees,
        permissions: ['CAN VIEW FUNCTIONAL FEES AMOUNTS'],
      },
      {
        id: 4,
        ...routePath.otherFees,
        permissions: ['CAN VIEW OTHER FEES AMOUNTS'],
      },
      {
        id: 5,
        ...routePath.feesWaivers,
        permissions: ['CAN VIEW FEES WAIVER SCHEMES'],
      },
      {
        id: 6,
        ...routePath.feesPreview,
        permissions: ['CAN PREVIEW FEES'],
      },
      {
        id: 7,
        ...routePath.feesCopy,
        permissions: ['CAN DO FEES COPY'],
      },
      {
        id: 8,
        ...routePath.feesApprovals,
        permissions: ['CAN APPROVE FEES COPY'],
      },
      {
        id: 9,
        ...routePath.manageAffiliates,
        permissions: [], // ['CAN MANAGE AFFILIATES'],
      },
      {
        id: 10,
        ...routePath.graduationFees,
        permissions: [], // ['CAN VIEW GRADUATION FEES'],
      },
    ],
  },
  {
    ...apps.policyMgt,
    img: institutionPolicyImg,
    isProtected: true,
    order: 5,
    subAppRoutes: [
      {
        id: 1,
        ...routePath.feesPolicy,
        order: 1,
        permissions: [],
      },
      {
        id: 2,
        ...routePath.registrationPolicy,
        order: 2,
        permissions: [],
      },
      {
        id: 3,
        ...routePath.admissionsPolicy,
        order: 3,
        permissions: ['CAN VIEW ADMISSION FEES POLICIES'],
      },
      {
        id: 4,
        ...routePath.resultsPolicy,
        order: 4,
        permissions: ['CAN VIEW RESULT CATEGORY LIST POLICIES'],
      },
      {
        id: 5,
        ...routePath.resultsBlockingPolicy,
        order: 5,
        permissions: ['CAN VIEW RESULT CATEGORY LIST POLICIES'],
      },
      {
        id: 6,
        ...routePath.timetablePolicy,
        order: 6,
        permissions: [],
      },
      {
        id: 7,
        ...routePath.servicePolicy,
        order: 7,
        permissions: [],
      },
    ],
  },
  {
    ...apps.eventMgt,
    img: scheduleImg,
    isProtected: true,
    order: 6,
    subAppRoutes: [
      {
        id: 1,
        ...routePath.academicYear,
        order: 1,
        permissions: [],
      },
      {
        id: 2,
        ...routePath.resultEvent,
        order: 2,
        permissions: [],
      },
      {
        id: 3,
        ...routePath.graduationEvent,
        order: 3,
        permissions: [],
      },
    ],
  },
  {
    ...apps.admissionMgt,
    img: admissionImg,
    isProtected: true,
    order: 7,
    subAppRoutes: [
      {
        id: 2,
        ...routePath.admission,
        order: 2,
        permissions: ['CAN VIEW ADMISSION SCHEMES'],
      },
      {
        id: 3,
        ...routePath.admissionForms,
        order: 3,
        permissions: ['CAN VIEW ADMISSION FORMS'],
      },
      {
        id: 4,
        ...routePath.applicant,
        order: 4,
        permissions: ['CAN VIEW APPLICANT RECORDS'],
      },
      {
        id: 5,
        ...routePath.manageApplicants,
        order: 5,
        permissions: ['CAN OPEN NEW ADMISSIONS'],
      },
      {
        id: 6,
        ...routePath.searchApplicants,
        order: 6,
        permissions: [],
      },
      {
        id: 7,
        ...routePath.weightingCriteria,
        order: 7,
        permissions: [],
      },
      {
        id: 8,
        ...routePath.weightingAndSelection,
        order: 8,
        permissions: [],
      },
      {
        id: 9,
        ...routePath.admittedStudents,
        order: 9,
        permissions: [],
      },
      {
        id: 10,
        ...routePath.changeOfProgramme,
        order: 10,
        permissions: [],
      },
      {
        id: 11,
        ...routePath.reports,
        order: 11,
        permissions: [],
      },
      {
        id: 12,
        ...routePath.unebCenter,
        order: 12,
        permissions: [],
      },
      {
        id: 13,
        ...routePath.applicationHistory,
        order: 13,
        permissions: [],
      },
    ],
  },
  {
    ...apps.studentRecordsMgt,
    img: studentRecords,
    isProtected: true,
    order: 8,
    subAppRoutes: [
      {
        id: 1,
        ...routePath.studentsDashboard,
        order: 2,
        permissions: ['CAN VIEW DASHBOARD'],
      },
      {
        id: 2,
        ...routePath.studentsInformation,
        order: 1,
        permissions: ['CAN VIEW STUDENT RECORDS'],
      },
      {
        id: 3,
        ...routePath.studentsApproval,
        order: 3,
        permissions: ['CAN APPROVE STUDENT UPLOADS'],
      },
    ],
  },
  {
    ...apps.photoAndBioMgt,
    img: studentPhotos,
    isProtected: true,
    order: 9,
    subAppRoutes: [
      {
        id: 1,
        ...routePath.studentsPhotos,
        permissions: ['CAN VIEW STUDENTS RECORDS'],
      },
      {
        id: 2,
        ...routePath.identificationProduction,
        permissions: [],
      },
    ],
  },
  {
    ...apps.enrollmentMgt,
    img: registrationImg,
    isProtected: true,
    order: 10,
    subAppRoutes: [
      {
        id: 1,
        ...routePath.studentsRegistration,
        permissions: [],
      },
      {
        id: 2,
        ...routePath.previousEnrollment,
        permissions: [],
      },
      {
        id: 3,
        ...routePath.registrationReports,
        permissions: [],
      },
      {
        id: 4,
        ...routePath.administrativeRegistration,
        permissions: [],
      },
      {
        id: 5,
        ...routePath.examinationTracking,
        permissions: ['CAN VIEW EXAM TRACKER'],
      },
    ],
  },
  {
    ...apps.staffAndStudentMgt,
    img: identityImg,
    isProtected: true,
    order: 11,
    subAppRoutes: [
      {
        id: 1,
        ...routePath.studentIdentification,
        permissions: [],
      },
      {
        id: 2,
        ...routePath.staffIdentification,
        permissions: [],
      },
    ],
  },
  {
    ...apps.paymentAndTransactionMgt,
    img: studentTransactionImg,
    isProtected: true,
    order: 12,
    subAppRoutes: [
      {
        id: 1,
        ...routePath.paymentsAndTransactions,
        permissions: ['CAN VIEW STUDENTS FINANCIAL TRANSACTIONS'],
      },
      {
        id: 2,
        ...routePath.pendingApprovals,
        permissions: ['CAN VIEW PENDING APPROVALS'],
      },
      {
        id: 3,
        ...routePath.paymentsReports,
        permissions: ['CAN VIEW PAYMENT REPORTS'],
      },
      {
        id: 4,
        ...routePath.otherPaymentReports,
        permissions: ['CAN VIEW PAYMENT REPORTS'],
      },
      {
        id: 5,
        ...routePath.bulkPayments,
        permissions: ['CAN MANAGE BULK PAYMENTS'],
      },
      {
        id: 6,
        ...routePath.bulkDirectPosts,
        permissions: ['CAN PERFORM BULK DIRECT POST'],
      },
      {
        id: 7,
        ...routePath.bulkManualInvoices,
        // permissions: ['CAN CREATE BULK MANUAL INVOICE'],
        permissions: [],
      },
      {
        id: 8,
        ...routePath.previousPayments,
        permissions: ['CAN MANAGE PREVIOUS PAYMENTS'],
      },
      {
        id: 9,
        ...routePath.sponsor,
        permissions: ['CAN MANAGE SPONSORS'],
      },
      {
        id: 10,
        ...routePath.refundPayments,
        permissions: ['CAN MANAGE REFUNDS'],
      },
    ],
  },
  {
    ...apps.universalPaymentMgt,
    img: UniversalPaymentsImg,
    isProtected: true,
    order: 13,
    subAppRoutes: [
      {
        id: 1,
        ...routePath.universalPaymentDashboard,
        order: 1,
        permissions: ['CAN VIEW DASHBOARD'],
      },
      {
        id: 4,
        ...routePath.universalPaymentSearchTransactions,
        order: 4,
        permissions: ['CAN SEARCH TRANSACTIONS'],
      },
      {
        id: 2,
        ...routePath.universalPaymentFinancialYear,
        order: 2,
        permissions: ['CAN VIEW DASHBOARD'],
      },
      {
        id: 3,
        ...routePath.universalPaymentTransactions,
        order: 3,
        permissions: ['CAN VIEW TRANSACTION REPORTS'],
      },
      {
        id: 5,
        ...routePath.configUniversalPaymentChartOfAccounts,
        order: 5,
        permissions: ['CAN VIEW CHART OF ACCOUNTS'],
      },
      {
        id: 6,
        ...routePath.configUniversalPayment,
        order: 6,
        permissions: ['CAN VIEW RECEIVABLES'],
      },
      {
        id: 7,
        ...routePath.universalInvoices,
        order: 7,
        permissions: ['CAN VIEW INVOICES'],
      },
      {
        id: 8,
        ...routePath.universalPaymentReports,
        order: 8,
        permissions: ['CAN VIEW REPORTS'],
      },
      {
        id: 9,
        ...routePath.notificationReport,
        order: 9,
        permissions: ['CAN VIEW REPORTS'],
      },
    ],
  },
  {
    ...apps.courseAssignmentMgt,
    img: courseMgtImg,
    isProtected: true,
    order: 14,
    subAppRoutes: [
      {
        id: 1,
        ...routePath.courseAssignment,
        permissions: [],
      },
      {
        id: 2,
        ...routePath.facultyResultsReport,
        permissions: ['CAN VIEW FACULTY SUBMITTED RESULTS'],
      },
      {
        id: 3,
        ...routePath.quatecResultReports,
        permissions: ['CAN VIEW QUATEC SUBMITTED RESULTS'],
      },
      {
        id: 4,
        ...routePath.senateResultsReport,
        permissions: ['CAN VIEW SENATE SUBMITTED RESULTS'],
      },
      {
        id: 5,
        ...routePath.teachingBlock,
        permissions: [],
      },
      {
        id: 6,
        ...routePath.teachingTimeTable,
        permissions: [],
      },
      {
        id: 7,
        ...routePath.semesterCourseLoad,
        permissions: [],
      },
    ],
  },
  {
    ...apps.businessIntelligentMgt,
    img: businessIntelligenceImg,
    order: 15,
    subAppRoutes: [
      {
        id: 1,
        ...routePath.academicRegistrar,
        permissions: ['CAN VIEW ENROLMENT REPORTS'],
      },
      {
        id: 2,
        ...routePath.revenueUnit,
        permissions: ['CAN VIEW REVENUE REPORTS'],
      },
      {
        id: 3,
        ...routePath.resultsUnit,
        permissions: ['CAN VIEW RESULT REPORTS'],
      },
      {
        id: 4,
        ...routePath.admissionsUnit,
        permissions: ['CAN VIEW ADMISSION REPORTS'],
      },
      {
        id: 5,
        ...routePath.graduationUnit,
        permissions: ['CAN VIEW GRADUATION REPORTS'],
      },
      {
        id: 6,
        ...routePath.serviceReport,
        permissions: ['CAN VIEW GRADUATION REPORTS'],
      },
    ],
  },
  {
    ...apps.resultMgt,
    img: resultMgtImg,
    isProtected: true,
    order: 16,
    subAppRoutes: [
      // {
      //   id: 1,
      //   ...routePath.resultManager,
      //   permissions: [],
      // },
      {
        id: 2,
        ...routePath.resultsBulkUpload,
        permissions: ['CAN UPLOAD RESULTS VIA DIRECT UPLOAD'],
      },
      {
        id: 3,
        ...routePath.resultsMarksView,
        permissions: ['CAN MANAGE RESULTS'],
      },
      {
        id: 4,
        ...routePath.departmentResultsApprovals,
        permissions: ['CAN APPROVE RESULT BATCHES'],
      },
      {
        id: 5,
        ...routePath.semesterAnalytics,
        permissions: ['CAN VIEW SEMESTER ANALYTICS'],
      },
      {
        id: 6,
        ...routePath.manageGrades,
        permissions: ['CAN MANAGE GRADES'],
      },
      {
        id: 7,
        ...routePath.graduationListView,
        permissions: ['CAN MANAGE GRADUATION'],
      },
      {
        id: 8,
        ...routePath.departmentReport,
        permissions: ['CAN VIEW DEPARTMENT REPORTS'],
      },
      {
        id: 9,
        ...routePath.facultyReport,
        permissions: ['CAN VIEW FACULTY REPORTS'],
      },
      {
        id: 10,
        ...routePath.senateReport,
        permissions: ['CAN VIEW SENATE REPORTS'],
      },
      {
        id: 11,
        ...routePath.searchResults,
        permissions: ['CAN SEARCH STUDENT RESULTS'],
      },
      {
        id: 12,
        ...routePath.dissertation,
        permissions: [],
      },
      {
        id: 13,
        ...routePath.userLogs,
        permissions: [],
      },
    ],
  },
  {
    ...apps.deanMgt,
    img: welfareImg,
    isProtected: true,
    order: 17,
    subAppRoutes: [
      {
        id: 1,
        ...routePath.deanAndStudents,
        permissions: [],
        order: 1,
        hidden: false,
      },
      // {
      //   id: 2,
      //   ...routePath.staffIdentification2,
      //   permissions: [],
      //   order: 2,
      //   hidden: false,
      // },
    ],
  },
  {
    ...apps.humanResourceMgt,
    img: humanResourceImg,
    isProtected: true,
    order: 18,
    subAppRoutes: [
      {
        id: 1,
        ...routePath.humanResource,
        permissions: [],
        order: 1,
        hidden: false,
      },
    ],
  },
  {
    ...apps.academicDocumentMgt,
    img: academicDocImg,
    isProtected: true,
    order: 19,
    subAppRoutes: [
      {
        id: 1,
        ...routePath.academicDocuments,
        permissions: ['CAN MANAGE TESTIMONIALS'],
        order: 1,
        hidden: false,
      },
      {
        id: 2,
        ...routePath.certificateDocuments,
        permissions: ['CAN MANAGE CERTIFICATES'],
        order: 2,
        hidden: false,
      },
      {
        id: 3,
        ...routePath.transcriptDocuments,
        permissions: ['CAN MANAGE TRANSCRIPTS'],
        order: 3,
        hidden: false,
      },
      {
        id: 4,
        ...routePath.documentSettings,
        permissions: [],
        order: 4,
        hidden: false,
      },
      {
        id: 5,
        ...routePath.transcriptPreview,
        permissions: ['CAN MANAGE TRANSCRIPTS'],
        order: 5,
        hidden: false,
      },
    ],
  },
  {
    ...apps.estatesMgt,
    img: estatesMgtImg,
    isProtected: true,
    order: 20,
    subAppRoutes: [
      {
        id: 1,
        ...routePath.estateManagement,
        permissions: [],
        order: 1,
        hidden: false,
      },
    ],
  },
  {
    ...apps.eVotingMgt,
    img: eVotingImg,
    isProtected: true,
    order: 21,
    subAppRoutes: [
      {
        id: 1,
        ...routePath.electivePositions,
        permissions: [],
        order: 1,
        hidden: false,
      },
      // {
      //   id: 2,
      //   ...routePath.eVotingCandidates,
      //   permissions: [],
      //   order: 2,
      //   hidden: false,
      // },
    ],
  },
  {
    ...apps.medicalServicesMgt,
    img: medicalServicesImg,
    isProtected: true,
    order: 22,
    subAppRoutes: [
      {
        id: 1,
        ...routePath.medicalServices,
        permissions: [],
        order: 1,
        hidden: false,
      },
    ],
  },
  {
    ...apps.outreachMgt,
    img: outreachMgtImg,
    isProtected: true,
    order: 23,
    subAppRoutes: [
      {
        id: 1,
        ...routePath.outReachServices,
        permissions: [],
        order: 1,
        hidden: false,
      },
    ],
  },
  {
    ...apps.pointOfServiceMgt,
    img: pointOfServiceImg,
    isProtected: true,
    order: 24,
    subAppRoutes: [
      {
        id: 1,
        ...routePath.pointOfServices,
        permissions: [],
        order: 1,
        hidden: false,
      },
    ],
  },
  {
    ...apps.studentClearanceMgt,
    img: studentClearances,
    isProtected: true,
    order: 25,
    subAppRoutes: [
      {
        id: 1,
        ...routePath.financeClearance,
        permissions: ['CAN VIEW FINANCE CLEARANCES'],
        order: 1,
        hidden: false,
      },
    ],
  },
  {
    ...apps.userProfileMgt,
    isProtected: false,
    order: 26,
    subAppRoutes: [
      {
        id: 1,
        ...routePath.profile,
        permissions: [],
      },
      {
        id: 3,
        ...routePath.myRoleGroups,
        permissions: [],
      },
      {
        id: 4,
        ...routePath.myRoles,
        permissions: [],
      },
      {
        id: 5,
        ...routePath.profileCalendar,
        permissions: [],
      },
      {
        id: 6,
        ...routePath.userProfileLogs,
        permissions: [],
      },
    ],
  },
  {
    ...apps.studentSupportMgt,
    img: studentSupportImg,
    isProtected: true,
    order: 27,
    subAppRoutes: [
      {
        id: 1,
        ...routePath.studentPasswordReset,
        permissions: [],
        order: 1,
        hidden: false,
      },
      {
        id: 2,
        ...routePath.editStudentData,
        permissions: [],
        order: 2,
        hidden: false,
      },
      {
        id: 3,
        ...routePath.supportStudentInvoices,
        permissions: [],
        order: 3,
        hidden: false,
      },
      {
        id: 4,
        ...routePath.staffGenerateOTP,
        permissions: ['CAN GENERATE STAFF OTP'],
        order: 4,
        hidden: false,
      },
    ],
  },
  {
    ...apps.NTCMgt,
    img: ntcResultsImg,
    isProtected: true,
    order: 28,
    subAppRoutes: [
      {
        id: 1,
        ...routePath.NTCSubjects,
        permissions: [],
        // permissions: ['CAN MANAGE NTC SUBJECTS'],
        order: 1,
        hidden: false,
      },
      {
        id: 2,
        ...routePath.NTCStudents,
        permissions: ['CAN VIEW NTC STUDENTS'],
        order: 2,
        hidden: false,
      },
      {
        id: 3,
        ...routePath.NTCUploadStudents,
        permissions: ['CAN UPLOAD NTC STUDENTS'],
        order: 4,
        hidden: false,
      },
      {
        id: 4,
        ...routePath.NTCStudentsResults,
        permissions: ['CAN VIEW NTC RESULTS'],
        order: 4,
        hidden: false,
      },
      {
        id: 5,
        ...routePath.NTCUploadStudentsResults,
        permissions: ['CAN UPLOAD NTC RESULTS'],
        order: 5,
        hidden: false,
      },
      // {
      //   id: 6,
      //   ...routePath.NTCTermlyResults,
      //   permissions: ['CAN VIEW NTC RESULTS'],
      //   order: 6,
      //   hidden: false,
      // },
      {
        id: 7,
        ...routePath.NTCAcademicDocuments,
        permissions: [],
        order: 7,
        hidden: false,
      },
    ],
  },
  {
    ...apps.lecturerMgt,
    img: lecturerMgtImg,
    isProtected: true,
    order: 29,
    subAppRoutes: [
      {
        id: 1,
        ...routePath.lecturerCourses,
        permissions: [],
        order: 1,
        hidden: false,
      },
      {
        id: 2,
        ...routePath.lecturerStudents,
        permissions: [],
        order: 2,
        hidden: false,
      },
    ],
  },
  {
    ...apps.emisIntegrationMgt,
    img: integrationImg,
    isProtected: true,
    order: 30,
    subAppRoutes: [
      {
        id: 1,
        ...routePath.emisSponsors,
        permissions: ['CAN VIEW EMIS SPONSORS'],
        order: 1,
        hidden: false,
      },
      {
        id: 2,
        ...routePath.emisEntrySchemes,
        permissions: ['CAN VIEW EMIS ADMISSION SCHEMES'],
        order: 2,
        hidden: true,
      },
      {
        id: 3,
        ...routePath.emisStudyLevels,
        permissions: ['CAN VIEW EMIS STUDY LEVEL'],
        order: 3,
        hidden: false,
      },
      {
        id: 4,
        ...routePath.emisCampuses,
        permissions: ['CAN VIEW EMIS CAMPUSES'],
        order: 4,
        hidden: false,
      },
      {
        id: 5,
        ...routePath.emisProgrammes,
        permissions: ['CAN VIEW EMIS PROGRAMMES'],
        order: 5,
        hidden: false,
      },
      {
        id: 6,
        ...routePath.emisStudentRecords,
        permissions: [],
        order: 6,
        hidden: false,
      },
      {
        id: 7,
        ...routePath.emisReports,
        permissions: [],
        order: 7,
        hidden: false,
      },
    ],
  },
  {
    ...apps.pujabMgt,
    img: pujabImg,
    isProtected: true,
    order: 31,
    subAppRoutes: [
      {
        id: 1,
        ...routePath.pujabInstitutions,
        permissions: [],
        order: 1,
        hidden: false,
      },
      {
        id: 2,
        ...routePath.pujabAdmissions,
        permissions: [],
        order: 2,
        hidden: false,
      },
      {
        id: 3,
        ...routePath.pujabReports,
        permissions: [],
        order: 3,
        hidden: false,
      },
      {
        id: 4,
        ...routePath.pujabAdmittedApplicants,
        permissions: [],
        order: 4,
        hidden: false,
      },
      {
        id: 5,
        ...routePath.pujabSchoolAdmin,
        permissions: [],
        order: 5,
        hidden: false,
      },
    ],
  },
  {
    ...apps.itsDataMgt,
    img: itsDataImg,
    isProtected: true,
    order: 32,
    subAppRoutes: [
      {
        id: 1,
        ...routePath.searchData,
        permissions: [],
        order: 1,
        hidden: false,
      },
    ],
  },
  {
    ...apps.auditMgt,
    img: auditTrailsImg,
    isProtected: true,
    order: 33,
    subAppRoutes: [
      {
        id: 1,
        ...routePath.auditTrails,
        permissions: [],
        order: 1,
        hidden: false,
      },
      {
        id: 2,
        ...routePath.applicationTrails,
        permissions: [],
        order: 2,
        hidden: false,
      },
    ],
  },
  {
    ...apps.specialEntryMgt,
    img: pujabImg,
    isProtected: true,
    order: 34,
    subAppRoutes: [
      {
        id: 1,
        ...routePath.specialEntryAdmissions,
        permissions: [],
        order: 1,
        hidden: false,
      },
      {
        id: 2,
        ...routePath.specialEntryReports,
        permissions: [],
        order: 2,
        hidden: false,
      },
    ],
  },
];

export default appRoutes;
