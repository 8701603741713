const timetablePolicyActions = {
  GET_TIMETABLE_POLICY_REQUEST: 'GET_TIMETABLE_POLICY_REQUEST',
  GET_TIMETABLE_POLICY_SUCCESS: 'GET_TIMETABLE_POLICY_SUCCESS',
  GET_TIMETABLE_POLICY_ERROR: 'GET_TIMETABLE_POLICY_ERROR',

  CREATE_TIMETABLE_POLICY_REQUEST: 'CREATE_TIMETABLE_POLICY_REQUEST',
  CREATE_TIMETABLE_POLICY_SUCCESS: 'CREATE_TIMETABLE_POLICY_SUCCESS',
  CREATE_TIMETABLE_POLICY_ERROR: 'CREATE_TIMETABLE_POLICY_ERROR',

  UPDATE_TIMETABLE_POLICY_REQUEST: 'UPDATE_TIMETABLE_POLICY_REQUEST',
  UPDATE_TIMETABLE_POLICY_SUCCESS: 'UPDATE_TIMETABLE_POLICY_SUCCESS',
  UPDATE_TIMETABLE_POLICY_ERROR: 'UPDATE_TIMETABLE_POLICY_ERROR',

  DELETE_TIMETABLE_POLICY_REQUEST: 'DELETE_TIMETABLE_POLICY_REQUEST',
  DELETE_TIMETABLE_POLICY_SUCCESS: 'DELETE_TIMETABLE_POLICY_SUCCESS',
  DELETE_TIMETABLE_POLICY_ERROR: 'DELETE_TIMETABLE_POLICY_ERROR',

  SET_TIMETABLE_POLICY_TO_EDIT: 'SET_TIMETABLE_POLICY_TO_EDIT',

  getTimetablePolicies: (params) => ({
    type: timetablePolicyActions.GET_TIMETABLE_POLICY_REQUEST,
    params,
  }),

  createTimetablePolicy: (data) => ({
    type: timetablePolicyActions.CREATE_TIMETABLE_POLICY_REQUEST,
    data,
  }),

  updateTimetablePolicy: (policyId, data) => ({
    type: timetablePolicyActions.UPDATE_TIMETABLE_POLICY_REQUEST,
    data,
    policyId,
  }),

  deleteTimetablePolicy: (policyId) => ({
    type: timetablePolicyActions.DELETE_TIMETABLE_POLICY_REQUEST,
    policyId,
  }),

  setPolicyToEdit: (policyToEdit) => ({
    type: timetablePolicyActions.SET_TIMETABLE_POLICY_TO_EDIT,
    policyToEdit,
  }),
};

export default timetablePolicyActions;
