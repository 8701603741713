import { timetablePolicyActions } from '../../actions';
import initialState from '../../initialState';

const timetablePolicy = (state = initialState.timetablePolicy, actions) => {
  switch (actions.type) {
    case timetablePolicyActions.GET_TIMETABLE_POLICY_REQUEST:
      return {
        ...state,
        getTimetablePolicies: true,
        timetablePoliciesError: {},
      };

    case timetablePolicyActions.GET_TIMETABLE_POLICY_SUCCESS:
      return {
        ...state,
        timetablePolicies: actions.data.data,
        getTimetablePolicies: false,
        timetablePoliciesError: {},
      };
    case timetablePolicyActions.GET_TIMETABLE_POLICY_ERROR:
      return {
        ...state,
        getTimetablePolicies: false,
        timetablePoliciesError: actions.error,
      };

    case timetablePolicyActions.CREATE_TIMETABLE_POLICY_REQUEST:
      return {
        ...state,
        createTimetablePolicy: true,
        createTimetablePolicyError: {},
      };

    case timetablePolicyActions.CREATE_TIMETABLE_POLICY_SUCCESS:
      return {
        ...state,
        createTimetablePolicy: false,
        createTimetablePolicyError: {},
      };

    case timetablePolicyActions.CREATE_TIMETABLE_POLICY_ERROR:
      return {
        ...state,
        createTimetablePolicy: false,
        createTimetablePolicyError: actions.error,
      };

    case timetablePolicyActions.UPDATE_TIMETABLE_POLICY_REQUEST:
      return {
        ...state,
        updateTimetablePolicy: true,
        updateTimetablePolicyError: {},
      };

    case timetablePolicyActions.UPDATE_TIMETABLE_POLICY_SUCCESS:
      return {
        ...state,
        updateTimetablePolicy: false,
        updateTimetablePolicyError: {},
      };

    case timetablePolicyActions.UPDATE_TIMETABLE_POLICY_ERROR:
      return {
        ...state,
        updateTimetablePolicy: false,
        updateTimetablePolicyError: actions.error,
      };

    case timetablePolicyActions.DELETE_TIMETABLE_POLICY_REQUEST:
      return {
        ...state,
        deleteTimetablePolicy: true,
        deleteTimetablePolicyError: {},
      };

    case timetablePolicyActions.DELETE_TIMETABLE_POLICY_SUCCESS:
      return {
        ...state,
        deleteTimetablePolicy: false,
        deleteTimetablePolicyError: {},
      };

    case timetablePolicyActions.DELETE_TIMETABLE_POLICY_ERROR:
      return {
        ...state,
        deleteTimetablePolicy: false,
        deleteTimetablePolicyError: actions.error,
      };

    case timetablePolicyActions.SET_TIMETABLE_POLICY_TO_EDIT:
      return {
        ...state,
        timetablePolicyToEdit: actions.policyToEdit,
      };

    default:
      return state;
  }
};

export default timetablePolicy;
