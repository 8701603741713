import {
  ApartmentOutlined,
  AppstoreOutlined,
  AuditOutlined,
  BankOutlined,
  BarChartOutlined,
  BarsOutlined,
  BranchesOutlined,
  CalculatorOutlined,
  CalendarOutlined,
  CarryOutOutlined,
  CheckCircleOutlined,
  ClusterOutlined,
  CodeOutlined,
  ContactsOutlined,
  ControlOutlined,
  CopyOutlined,
  DatabaseOutlined,
  DiffOutlined,
  EditOutlined,
  FileDoneOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
  FileProtectOutlined,
  FileSearchOutlined,
  FormOutlined,
  HourglassOutlined,
  IdcardOutlined,
  KeyOutlined,
  LinkOutlined,
  LockOutlined,
  OrderedListOutlined,
  PieChartOutlined,
  ProfileOutlined,
  RollbackOutlined,
  SettingOutlined,
  SnippetsOutlined,
  SolutionOutlined,
  TeamOutlined,
  ThunderboltOutlined,
  ToolOutlined,
  UploadOutlined,
  UserOutlined,
  UserSwitchOutlined,
  VerifiedOutlined,
} from '@ant-design/icons';
import { singular } from 'pluralize';
import { FaChartArea, FaUserClock } from 'react-icons/fa';
import { HiOutlineViewGrid, HiOutlineZoomIn } from 'react-icons/hi';

const RoutePaths = {
  dashboard: {
    path: 'my-applications',
    name: 'my-applications',
    title: 'My APPLICATIONS',
    icon: AppstoreOutlined,
  },
  users: {
    path: 'users',
    name: 'users',
    title: 'Users',
    icon: TeamOutlined,
  },
  comingSoon: {
    path: 'coming-soon',
    name: 'coming-soon',
    title: 'Module Coming Soon',
    icon: LockOutlined,
  },
  login: {
    path: 'login',
    name: 'authentication',
    title: 'Authentication',
  },
  resetPassword: {
    path: 'reset-password',
    name: 'authentication',
    title: 'Authentication',
  },
  changeDefaultPassword: {
    path: '/change-default-password',
    name: 'change-default-password',
    title: 'Change Default Password',
  },
  changeExpiredPassword: {
    path: '/change-expired-password',
    name: 'change-expired-password',
    title: 'Change Expired Password',
  },
  requestEmailVerification: {
    path: '/email-verification',
    name: 'email-verification',
    title: 'Email Verification',
  },
  phoneVerification: {
    path: '/verify-phone',
    name: 'phone-verification',
    title: 'Phone Verification',
  },
  verifyEmail: {
    path: '/email/verification/:token',
    name: 'verify-email',
    title: 'Verify Email',
  },
  metadata: {
    path: 'institution-setup/meta-data',
    name: 'meta-data',
    title: 'META DATA',
    icon: SettingOutlined,
  },
  institutionStructure: {
    path: 'institution-setup/structure',
    name: 'academic-units',
    title: 'Initial Setup',
    icon: KeyOutlined,
  },
  faculties: {
    path: 'institution-setup/faculties',
    name: 'faculties',
    title: process.env.REACT_APP_INSTITUTION_FACULTY_ALIAS || 'Faculties',
    icon: ApartmentOutlined,
  },
  college: {
    path: 'institution-setup/colleges',
    name: 'colleges',
    title: 'Colleges',
    icon: ApartmentOutlined,
  },
  departments: {
    path: 'institution-setup/departments',
    name: 'departments',
    title: 'Departments',
    icon: ApartmentOutlined,
  },
  institutionDashboard: {
    path: 'institution-setup/dashboard',
    name: 'institution-dashboard',
    title: 'Dashboard',
    icon: AppstoreOutlined,
  },
  courseUnits: {
    path: 'programme-mgt/course-units',
    name: 'course-units',
    title: 'Course Units',
    icon: ApartmentOutlined,
  },
  programmeSearch: {
    path: 'programme-mgt/programme-search',
    name: 'programme-search',
    title: 'Summarized Search',
    icon: FileSearchOutlined,
  },
  programmesDashboard: {
    path: 'programme-mgt/dashboard',
    name: 'programme-dashboard',
    title: 'Dashboard',
    icon: AppstoreOutlined,
  },
  internalServerError: {
    path: 'internal-server-error',
    name: 'internal-server-error',
    title: 'Users',
    icon: TeamOutlined,
  },
  notFoundError: {
    path: 'page-not-found',
    name: 'page-not-found',
    title: 'Page Not Found',
  },
  profile: {
    path: 'auth/my-profile',
    name: 'auth-profile',
    title: 'My Profile',
    icon: VerifiedOutlined,
  },
  programme: {
    path: 'programme-mgt/programmes',
    name: 'programmes',
    title: 'Programmes',
    icon: ProfileOutlined,
  },
  programmeSetting: {
    path: 'programme-mgt/settings',
    name: 'programme-settings',
    title: 'Programme Settings',
    icon: SettingOutlined,
  },
  systemUser: {
    path: 'system-access/users',
    name: 'access-management',
    title: 'Access management',
    icon: SolutionOutlined,
  },
  userRole: {
    path: 'system-access/manage-roles',
    name: 'user-role',
    title: 'Manage Role Groups',
    icon: UserSwitchOutlined,
  },
  myRoleGroups: {
    path: 'auth/my-role-groups',
    name: 'user-role-groups',
    title: 'Manage Role Groups',
    icon: TeamOutlined,
  },
  profileCalendar: {
    path: 'auth/my-academic-calendar',
    name: 'user-academic-calendar',
    title: 'Academic Calendar',
    icon: CalendarOutlined,
  },
  userProfileLogs: {
    path: 'auth/my-user-logs',
    name: 'profile-user-logs',
    title: 'My Activity Logs',
    icon: FileSearchOutlined,
  },
  studentPasswordReset: {
    path: 'auth/password-reset',
    name: 'user-generate-OTP',
    title: 'Generate Student OTP',
    icon: CodeOutlined,
  },
  staffGenerateOTP: {
    path: 'auth/staff-otp',
    name: 'staff-generate-OTP',
    title: 'Generate Staff OTP',
    icon: CodeOutlined,
  },
  editStudentData: {
    path: 'auth/edit-student-data',
    name: 'edit-student-data',
    title: 'Edit Student Contacts',
    icon: EditOutlined,
  },
  supportStudentInvoices: {
    path: 'auth/support-student-invoices',
    name: 'support-student-invoices',
    title: 'GENERATE STUDENT PRNs',
    icon: FileDoneOutlined,
  },
  myRoles: {
    path: 'auth/my-roles',
    name: 'my-roles',
    title: 'My Roles',
    icon: ClusterOutlined,
  },
  userAccessApp: {
    path: 'system-access/apps',
    name: 'system-apps',
    title: 'System apps',
    icon: AppstoreOutlined,
  },
  securityProfile: {
    path: 'system-access/security-profile',
    name: 'security-profiles',
    title: 'Security Profiles',
  },
  feesManagement: {
    path: 'fees-management/fees-elements',
    name: 'fees-management',
    title: 'Fees Elements',
    icon: BankOutlined,
  },
  tuition: {
    path: 'fees-management/tuition',
    name: 'tuition-amounts',
    title: 'Tuition Amounts',
    icon: CalculatorOutlined,
  },
  functionalFees: {
    path: 'fees-management/functional-fees',
    name: 'functional-fees',
    title: 'Functional Fees',
    icon: OrderedListOutlined,
  },
  otherFees: {
    path: 'fees-management/other-fees',
    name: 'other-fees',
    title: 'Other Fees',
    icon: BranchesOutlined,
  },
  feesWaivers: {
    path: 'fees-management/fees-waivers',
    name: 'fees-waivers',
    title: 'Fees Waivers',
    icon: CheckCircleOutlined,
  },
  feesPreview: {
    path: 'fees-management/fees-preview',
    name: 'fees-preview',
    title: 'Fees Preview',
    icon: CopyOutlined,
  },
  feesCopy: {
    path: 'fees-management/fees-copy',
    name: 'fees-copy',
    title: 'Fees Copy',
    icon: CopyOutlined,
  },
  feesApprovals: {
    path: 'fees-management/fees-approvals',
    name: 'approvals',
    title: 'Fees Approvals',
    icon: CheckCircleOutlined,
  },
  manageAffiliates: {
    path: 'fees-management/manage-affiliates',
    name: 'affiliates',
    title: 'Manage Affiliates',
    icon: ClusterOutlined,
  },
  graduationFees: {
    path: 'fees-management/graduation-fees',
    name: 'graduation-fees',
    title: 'Graduation Fees',
    icon: OrderedListOutlined,
  },
  financeClearance: {
    path: 'fees-management/financeClearance',
    name: 'financeClearance-fees',
    title: 'FINANCE CLEARANCE',
    icon: CheckCircleOutlined,
  },
  academicYear: {
    path: 'manage-events/academic-years',
    name: 'manage-events',
    title: 'ACADEMIC YEAR EVENTS',
    icon: CarryOutOutlined,
  },
  resultEvent: {
    path: 'manage-events/result',
    name: 'manage-result-events',
    title: 'RESULTS EVENTS',
    icon: ControlOutlined,
  },
  graduationEvent: {
    path: 'manage-events/graduation',
    name: 'manage-graduation-events',
    title: 'GRADUATION EVENTS',
    icon: CalendarOutlined,
  },

  admission: {
    path: 'admission-mgt/admissions',
    name: 'admission',
    title: 'Manage Schemes',
    icon: CopyOutlined,
  },
  unebCenter: {
    path: 'admission-mgt/uneb-center',
    name: 'admission',
    title: 'UNEB CENTERS',
    icon: ThunderboltOutlined,
  },
  admissionForms: {
    path: 'admission-mgt/forms',
    name: 'admission-forms',
    title: 'Application Forms',
    icon: FormOutlined,
  },
  applicationHistory: {
    path: 'admission-mgt/history',
    name: 'application-history',
    title: 'APPLICATION HISTORY',
    icon: FormOutlined,
  },
  applicant: {
    path: 'admission-mgt/running-admissions',
    name: 'running-admissions',
    title: 'Running Admissions',
    icon: DatabaseOutlined,
  },

  studentsInformation: {
    path: 'students-information',
    name: 'Students-Records',
    title: 'Students',
    icon: TeamOutlined,
  },

  studentsInformation2: {
    path: 'dean-students-information',
    name: 'Students-Records',
    title: 'Students Records',
    icon: IdcardOutlined,
  },
  studentsApproval: {
    path: 'students-information/approvals',
    name: 'Students-approvals',
    title: 'Student Approvals',
    icon: CheckCircleOutlined,
  },
  studentsDashboard: {
    path: 'students-information/dashboard',
    name: 'Students-Dashboard',
    title: 'Dashboard',
    icon: AppstoreOutlined,
  },
  studentIdentification: {
    path: 'student-identification',
    name: 'Student Identification',
    title: 'Student Identification',
    icon: IdcardOutlined,
  },
  studentIdentification2: {
    path: 'photo-student-identification',
    name: 'Student Identification',
    title: 'Student Identification',
    icon: UserOutlined,
  },
  staffIdentification: {
    path: 'dean-staff-identification',
    name: 'Staff Identification',
    title: 'Staff Identification',
    icon: IdcardOutlined,
  },
  staffIdentification2: {
    path: 'staff-identification',
    name: 'Staff Identification',
    title: 'Staff Identification',
    icon: IdcardOutlined,
  },
  identificationProduction: {
    path: 'identity-cards',
    name: 'ID Card Production',
    title: 'ID Card Production',
    icon: IdcardOutlined,
  },
  studentsPhotos: {
    path: 'photo-manager',
    name: 'Photos & Biometrics Capture',
    title: 'Photos & Biometrics',
    icon: ContactsOutlined,
  },
  studentsRegistration: {
    path: 'students-registration',
    name: 'Registration',
    title: 'Student Profile',
    icon: EditOutlined,
  },
  previousEnrollment: {
    path: 'previous-enrollment',
    name: 'previous-enrollment',
    title: 'Migrate Enrollments',
    icon: RollbackOutlined,
  },
  paymentsAndTransactions: {
    path: 'payments-and-transactions',
    name: 'payments-and-transactions',
    title: 'Student Profile',
    icon: IdcardOutlined,
  },
  previousPayments: {
    path: 'previous-payments',
    name: 'previous-payments',
    title: 'Migrate Payments',
    icon: RollbackOutlined,
  },
  pendingApprovals: {
    path: 'payments-and-transactions/pending-approvals',
    name: 'approvals',
    title: 'Pending Approvals',
    icon: HourglassOutlined,
  },
  bulkPayments: {
    path: 'payments-and-transactions/bulk-payments',
    name: 'bulk payments',
    title: 'Bulk Payments',
    icon: DatabaseOutlined,
  },
  bulkDirectPosts: {
    path: 'payments-and-transactions/bulk-direct-posts',
    name: 'bulk direct posts',
    title: 'Bulk Direct Posts',
    icon: DatabaseOutlined,
  },
  bulkManualInvoices: {
    path: 'payments-and-transactions/bulk-manual-invoicing',
    name: 'bulk manual invoice',
    title: 'Bulk Manual Invoicing',
    icon: CopyOutlined,
  },
  otherPaymentReports: {
    path: 'other-payment-reports',
    name: 'Other Reports',
    title: 'Other Reports',
    icon: BarChartOutlined,
  },
  sponsor: {
    path: 'universal-payments/sponsors',
    name: 'universal-payment-sponsors',
    title: 'Manage Sponsors',
    icon: UserSwitchOutlined,
  },
  refundPayments: {
    path: 'payments-and-transactions/refund-payments',
    name: 'Refund payments',
    title: 'Refund Payments',
    icon: LinkOutlined,
  },
  registrationReports: {
    path: 'registration-reports',
    name: 'Registration Reports',
    title: 'Registration Reports',
    icon: BarChartOutlined,
  },
  administrativeRegistration: {
    path: 'registration/administrative-registration',
    name: 'administrative-registration',
    title: 'Administrative Registration',
    icon: FileProtectOutlined,
  },
  examinationTracking: {
    path: 'registration/exam-tracking',
    name: 'exam-tracking',
    title: 'Exam Tracking',
    icon: FileDoneOutlined,
  },
  paymentsReports: {
    path: 'payments-reports',
    name: 'Payment Reports',
    title: 'Payment Reports',
    icon: BarChartOutlined,
  },
  manageApplicants: {
    path: 'admission-mgt/manage-applications',
    name: 'open-application',
    title: 'Manage Applicants',
    icon: UserSwitchOutlined,
  },
  searchApplicants: {
    path: 'admission-mgt/search-applicants',
    name: 'search-applicants',
    title: 'Search Applicants',
    icon: HiOutlineZoomIn,
  },

  admissionsDashboard: {
    path: 'admission-mgt/admissions-dashboard',
    name: 'admissions-dashboard',
    title: 'Admissions Dashboard',
    icon: HiOutlineViewGrid,
  },

  weightingCriteria: {
    path: 'admission-mgt/weighting-criteria',
    name: 'selection',
    title: 'Weighting Criteria',
    icon: DiffOutlined,
  },

  weightingAndSelection: {
    path: 'admission-mgt/weighting-and-selection',
    name: 'selection',
    title: 'Weighting & Selection',
    icon: HourglassOutlined,
  },

  admittedStudents: {
    path: 'admission-mgt/admitted-applicants',
    name: 'admitted',
    title: 'Admitted Applicants',
    icon: CheckCircleOutlined,
  },

  changeOfProgramme: {
    path: 'applicant-mgt/change-of-programme',
    name: 'programme-change',
    title: 'Change of Programme',
    icon: CopyOutlined,
  },

  reports: {
    path: 'applicant-mgt/admission-report',
    name: 'admission-report',
    title: 'Reports',
    icon: BarChartOutlined,
  },

  resultsView: {
    path: 'results-view',
    name: 'results-view',
    title: 'Results View',
    icon: AppstoreOutlined,
  },

  graduationListView: {
    path: 'graduation-list',
    name: 'graduation-list-view',
    title: 'Manage Graduation',
    icon: AuditOutlined,
  },

  graduationReport: {
    path: 'graduation-report',
    name: 'graduation-report',
    title: 'Graduation',
    icon: AuditOutlined,
  },

  manageGrades: {
    path: 'manage-result-grades',
    name: 'manage-result-grades',
    title: 'Manage Grades',
    icon: CalculatorOutlined,
  },
  semesterAnalytics: {
    path: 'results-analytics',
    name: 'results-analytics',
    title: 'Semester Analytics',
    icon: AuditOutlined,
  },
  departmentReport: {
    path: 'department-reports',
    name: 'department-reports',
    title: 'Department Report',
    icon: BarChartOutlined,
  },
  facultyReport: {
    path: 'faculty-reports',
    name: 'faculty-reports',
    title: `${singular(
      process.env.REACT_APP_INSTITUTION_FACULTY_ALIAS || 'Faculties'
    )} Report`,
    icon: BarChartOutlined,
  },
  senateReport: {
    path: 'senate-reports',
    name: 'senate-reports',
    title: 'Senate Report',
    icon: BarChartOutlined,
  },
  searchResults: {
    path: 'search-results',
    name: 'search-results',
    title: 'Search Results',
    icon: FileSearchOutlined,
  },
  dissertation: {
    path: 'dissertation',
    name: 'dissertation',
    title: 'DISSERTATIONS',
    icon: DatabaseOutlined,
  },
  userLogs: {
    path: 'user-logs',
    name: 'user-logs',
    title: 'User Logs',
    icon: FileDoneOutlined,
  },
  courseAssignment: {
    path: 'courses/course-assignment',
    name: 'course-assignment',
    title: 'Course Management',
    icon: ApartmentOutlined,
  },
  semesterCourseLoad: {
    path: 'courses/semester-course-load',
    name: 'semester-course-load',
    title: 'Semester Course Loads',
    icon: DatabaseOutlined,
  },
  teachingBlock: {
    path: 'courses/teaching-rooms',
    name: 'teaching-rooms',
    title: 'Teaching Facilities',
    icon: AppstoreOutlined,
  },
  teachingTimeTable: {
    path: 'courses/teaching-timetable',
    name: 'teaching-rooms',
    title: 'Teaching Timetable',
    icon: CalendarOutlined,
  },
  resultManager: {
    path: 'marks/mark-upload',
    name: 'mark-upload',
    title: 'Assessment Nodes',
    icon: ApartmentOutlined,
  },
  resultsBulkUpload: {
    path: 'marks/bulk-upload',
    name: 'results-mark-upload',
    title: 'Direct Upload',
    icon: UploadOutlined,
  },
  departmentResultsApprovals: {
    path: 'results-approval',
    name: 'results-approval',
    title: 'Results Actions',
    icon: CheckCircleOutlined,
  },
  resultsMarksView: {
    path: 'marks/results-view',
    name: 'results-view',
    title: 'Manage Results',
    icon: UserSwitchOutlined,
  },
  registrationPolicy: {
    path: 'institution-policy/registration',
    name: 'registration-policies',
    title: 'Registration Policies',
    icon: FileDoneOutlined,
  },
  feesPolicy: {
    path: 'institution-policy/fees',
    name: 'fees-policies',
    title: 'Fees Policies',
    icon: SettingOutlined,
  },
  admissionsPolicy: {
    path: 'institution-policy/admissions',
    name: 'admissions-policies',
    title: 'Admissions Policies',
    icon: ToolOutlined,
  },
  resultsPolicy: {
    path: 'institution-policy/results',
    name: 'results-policies',
    title: 'Results Policies',
    icon: SettingOutlined,
  },
  resultsBlockingPolicy: {
    path: 'institution-policy/results-blocking',
    name: 'results-blocking-policies',
    title: 'Results Blocking Policies',
    icon: FileExcelOutlined,
  },
  timetablePolicy: {
    path: 'institution-policy/timetable',
    name: 'timetable-policies',
    title: 'Timetable Policies',
    icon: CalendarOutlined,
  },
  servicePolicy: {
    path: 'institution-policy/services',
    name: 'student-services-policies',
    title: 'Change of Programme',
    icon: CopyOutlined,
  },
  configUniversalPayment: {
    path: 'universal-payments/config',
    name: 'universal-payments',
    title: 'Configure Receivables',
    icon: SettingOutlined,
  },
  configUniversalPaymentChartOfAccounts: {
    path: 'universal-payments/chart-of-accounts',
    name: 'universal-payments-chart-of-accounts',
    title: 'Chart Of Accounts',
    icon: BarsOutlined,
  },
  universalPaymentDashboard: {
    path: 'universal-payments/dashboard',
    name: 'universal-payment-dashboard',
    title: 'Dashboard',
    icon: AppstoreOutlined,
  },
  universalPaymentFinancialYear: {
    path: 'universal-payments/financialYr',
    name: 'universal-payment-financialYr',
    title: 'Financial Year',
    icon: BarChartOutlined,
  },
  universalPaymentTransactions: {
    path: 'universal-payments/transactions',
    name: 'universal-payment-transactions',
    title: 'Transaction Reports',
    icon: BarChartOutlined,
  },
  universalPaymentSearchTransactions: {
    path: 'universal-payments/search-transactions',
    name: 'universal-payment-search-transactions',
    title: 'Search Transactions',
    icon: FileSearchOutlined,
  },
  universalPaymentReports: {
    path: 'universal-payments/reports',
    name: 'universal-payment-reports',
    title: 'Revenue per Item',
    icon: FileDoneOutlined,
  },
  serviceReport: {
    path: 'universal-payments/service-reports',
    name: 'universal-payment-service-reports',
    title: 'Service & Notification Report',
    icon: PieChartOutlined,
  },
  universalInvoices: {
    path: 'universal-payments/invoices',
    name: 'universal-payment-invoices',
    title: 'Universal Invoices',
    icon: FileDoneOutlined,
  },
  academicRegistrar: {
    path: 'business-intelligence/registration-reports',
    name: 'registration-reports',
    title: 'ENROLLMENT & REGISTRATION REPORTS',
  },
  revenueUnit: {
    path: 'business-intelligence/revenue-unit',
    name: 'Revenue Reports',
    title: 'Revenue Reports ',
  },
  graduationUnit: {
    path: 'business-intelligence/graduation-unit',
    name: 'Graduation Reports',
    title: 'Graduation Reports ',
  },
  notificationReport: {
    path: 'business-intelligence/notification-report',
    name: 'Notification Reports',
    title: 'Service & Notification Report',
    icon: PieChartOutlined,
  },
  admissionsUnit: {
    path: 'business-intelligence/admissions-unit',
    name: 'Admission Reports',
    title: 'Admission Reports ',
    icon: PieChartOutlined,
  },
  resultsUnit: {
    path: 'business-intelligence/results-unit',
    name: 'Results Reports',
    title: 'Results Reports',
    icon: PieChartOutlined,
  },
  humanResource: {
    path: 'human-resource/coming-soon',
    name: 'human-resource',
    title: 'Human Resource',
    icon: PieChartOutlined,
  },
  deanAndStudents: {
    path: 'dean-and-students/',
    name: 'dean-and-students',
    title: 'Dean & Students',
  },
  academicDocuments: {
    path: 'academic-documents/testimonial',
    name: 'academic-documents',
    title: 'Testimonial',
    icon: FileProtectOutlined,
  },
  transcriptDocuments: {
    path: 'academic-documents/transcripts',
    name: 'transcripts',
    title: 'Manage Transcripts',
    icon: FileProtectOutlined,
  },
  certificateDocuments: {
    path: 'academic-documents/certificates',
    name: 'certificates',
    title: 'Manage Certificates',
    icon: FilePdfOutlined,
  },
  documentSettings: {
    path: 'academic-documents/settings',
    name: 'settings',
    title: 'Document Settings',
    icon: SettingOutlined,
  },
  transcriptPreview: {
    path: 'academic-documents/transcript-preview',
    name: 'settings',
    title: 'Document Preview',
    icon: FilePdfOutlined,
  },
  medicalServices: {
    path: 'medical-services/coming-soon',
    name: 'medical-services',
    title: 'Medical Services',
  },
  outReachServices: {
    path: 'out-reach-services/coming-soon',
    name: 'out-reach-services',
    title: 'Out Reach Mgt',
  },
  pointOfServices: {
    path: 'point-of-services/coming-soon',
    name: 'point-of-services',
    title: 'Point of Service',
  },
  studentClearances: {
    path: 'student-clearances/coming-soon',
    name: 'student-clearances',
    title: 'Student Clearances',
  },
  electivePositions: {
    path: 'e-voting/elective-positions',
    name: 'elective-positions',
    title: 'ELECTIVE POSITIONS',
  },
  eVotingCandidates: {
    path: 'e-voting/candidates',
    name: 'e-candidates',
    title: 'CANDIDATES',
  },
  estateManagement: {
    path: 'estate-management/coming-soon',
    name: 'estate-management',
    title: 'Estate Mgt',
  },
  NTCSubjects: {
    path: 'ntc-mgt/subjects',
    name: 'ntc-subjects',
    title: 'Manage NTC Subjects',
    icon: SettingOutlined,
  },
  NTCStudents: {
    path: 'ntc-mgt/students',
    name: 'ntc-management',
    title: 'Manage NTC Students',
    icon: UserSwitchOutlined,
  },
  NTCUploadStudents: {
    path: 'ntc-mgt/students/upload',
    name: 'ntc-student-upload',
    title: 'Upload NTC Students',
    icon: UploadOutlined,
  },
  NTCStudentsResults: {
    path: 'ntc-mgt/students-results',
    name: 'ntc-result-management',
    title: 'Manage NTC Results',
    icon: FileProtectOutlined,
  },
  NTCUploadStudentsResults: {
    path: 'ntc-mgt/students-results/upload',
    name: 'ntc-upload-result-management',
    title: 'Upload NTC Results',
    icon: UploadOutlined,
  },
  NTCTermlyResults: {
    path: 'ntc-mgt/students-results/analytics',
    name: 'ntc-termly-results',
    title: 'NTC TERMLY RESULTS',
    icon: BarChartOutlined,
  },
  NTCAcademicDocuments: {
    path: 'ntc-mgt/academic-documents',
    name: 'ntc-academic-documents',
    title: 'NTC ACADEMIC DOCS',
    icon: FilePdfOutlined,
  },
  lecturerCourses: {
    path: 'lecturer-module/courses',
    name: 'lecturer-courses',
    title: 'My Courses',
    icon: ApartmentOutlined,
  },
  lecturerStudents: {
    path: 'lecturer-module/students',
    name: 'enrolled-students',
    title: 'TEACHING TIMETABLE',
    icon: ProfileOutlined,
  },
  emisSponsors: {
    path: 'emis-management/sponsors',
    name: 'emis-sponsors',
    title: 'Sponsors',
    Icon: UserSwitchOutlined,
  },
  emisEntrySchemes: {
    path: 'emis-management/entry-schemes',
    name: 'entry-schemes',
    title: 'Entry Schemes',
    icon: DiffOutlined,
  },
  emisStudyLevels: {
    path: 'emis-management/study-levels',
    name: 'study-levels',
    title: 'Study Levels',
    icon: DatabaseOutlined,
  },
  emisCampuses: {
    path: 'emis-management/campuses',
    name: 'campuses',
    title: 'Campuses',
    icon: BankOutlined,
  },
  emisProgrammes: {
    path: 'emis-management/programmes',
    name: 'programmes',
    title: 'Programmes',
    icon: ProfileOutlined,
  },
  emisStudentRecords: {
    path: 'emis-management/students',
    name: 'Student Records',
    title: 'Student Records',
    icon: AuditOutlined,
  },
  emisReports: {
    path: 'emis-management/reports',
    name: 'reports',
    title: 'Reports',
    Icon: BarChartOutlined,
  },
  pujabInstitutions: {
    path: 'PUJAB-mgt/institutions',
    name: 'pujab-institutions',
    title: 'Institutions',
    icon: BankOutlined,
  },
  pujabAdmissions: {
    path: 'pujab-mgt/admissions',
    name: 'pujab-admissions',
    title: 'RUNNING ADMISSIONS',
    icon: DatabaseOutlined,
  },
  pujabReports: {
    path: 'pujab-mgt/reports',
    name: 'pujab-reports',
    title: 'PUJAB REPORTS',
    icon: BarChartOutlined,
  },
  pujabSchoolAdmin: {
    path: 'pujab-mgt/school-admins',
    name: 'school-admins',
    title: 'SCHOOL ADMINS',
    icon: TeamOutlined,
  },
  pujabAdmittedApplicants: {
    path: 'pujab-mgt/applicants',
    name: 'pujab-applicants',
    title: 'ADMITTED APPLICANTS',
    icon: CheckCircleOutlined,
  },
  specialEntryAdmissions: {
    path: 'mature-age-mgt/admissions',
    name: 'mature-age-admissions',
    title: 'RUNNING ADMISSIONS',
    icon: DatabaseOutlined,
  },
  specialEntryReports: {
    path: 'mature-age-mgt/reports',
    name: 'mature-age-reports',
    title: 'REPORTS',
    icon: BarChartOutlined,
  },
  searchData: {
    path: 'its-mgt/search',
    name: 'search-data',
    title: 'SEARCH DATA',
    icon: FileSearchOutlined,
  },
  auditTrails: {
    path: 'logs-mgt/logs',
    name: 'audit-user-logs',
    title: 'ACTIVITY LOGS',
    icon: FaUserClock,
  },
  applicationTrails: {
    path: 'logs-mgt/app-logs',
    name: 'audit-app-logs',
    title: 'APPLICATION LOGS',
    icon: AppstoreOutlined,
  },
  facultyResultsReport: {
    path: 'course-assignment/faculty-results-submission',
    name: 'faculty-submission-results',
    title: 'Faculty Results Report',
    icon: SnippetsOutlined,
  },
  quatecResultReports: {
    path: 'course-assignment/quatec-results-report',
    name: 'quatec-results-report',
    title: 'QUATEC Results Report',
    icon: FaChartArea,
  },
  senateResultsReport: {
    path: 'course-assignment/senate-results-report',
    name: 'senate-results-report',
    title: 'SENATE Results Report',
    icon: BarChartOutlined,
  },
};

export default RoutePaths;
