import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { timetablePolicyActions } from '../../actions';

function* getTimetablePolicies(actions) {
  try {
    const response = yield axios({
      url: 'institution-policy-mgt/timetable-policies/teaching-load-policy',
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: timetablePolicyActions.GET_TIMETABLE_POLICY_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: timetablePolicyActions.GET_TIMETABLE_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* createTimetablePolicy(actions) {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/timetable-policies/teaching-load-policy',
      method: 'POST',
      data: actions.data,
    });

    yield put({
      type: timetablePolicyActions.CREATE_TIMETABLE_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: timetablePolicyActions.GET_TIMETABLE_POLICY_REQUEST,
    });
    yield put({
      type: 'SHOW_TIMETABLE_POLICY_MODEL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: timetablePolicyActions.CREATE_TIMETABLE_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* deleteTimetablePolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/timetable-policies/teaching-load-policy/${actions.policyId}`,
      method: 'DELETE',
    });
    yield put({
      type: timetablePolicyActions.DELETE_TIMETABLE_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: timetablePolicyActions.GET_TIMETABLE_POLICY_REQUEST,
    });
    yield put({
      type: 'SHOW_TIMETABLE_POLICY_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: timetablePolicyActions.DELETE_TIMETABLE_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* updateTimetableFeesPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/timetable-policies/teaching-load-policy/${actions.policyId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: timetablePolicyActions.UPDATE_TIMETABLE_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: timetablePolicyActions.GET_TIMETABLE_POLICY_REQUEST,
    });
    yield put({
      type: 'SHOW_TIMETABLE_POLICY_MODEL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: timetablePolicyActions.UPDATE_TIMETABLE_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* watchGetTimetablePolicies() {
  yield takeLatest(
    timetablePolicyActions.GET_TIMETABLE_POLICY_REQUEST,
    getTimetablePolicies
  );
}

function* watchCreateTimetablePolicy() {
  yield takeLatest(
    timetablePolicyActions.CREATE_TIMETABLE_POLICY_REQUEST,
    createTimetablePolicy
  );
}

function* watchDeleteTimetablePolicy() {
  yield takeLatest(
    timetablePolicyActions.DELETE_TIMETABLE_POLICY_REQUEST,
    deleteTimetablePolicy
  );
}

function* watchUpdateTimetablePolicy() {
  yield takeLatest(
    timetablePolicyActions.UPDATE_TIMETABLE_POLICY_REQUEST,
    updateTimetableFeesPolicy
  );
}

export default [
  fork(watchGetTimetablePolicies),
  fork(watchCreateTimetablePolicy),
  fork(watchDeleteTimetablePolicy),
  fork(watchUpdateTimetablePolicy),
];
